import React, { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import anime from "animejs/lib/anime.es.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faBuilding, faThumbsUp } from "@fortawesome/free-solid-svg-icons";


import video from '../assets/videos/Stashify web video.mp4'
import video2 from '../assets/videos/Stashify.mp4'

import Account from '../assets/images/domain-icons/Account.png'
import Bank from '../assets/images/domain-icons/Bank.png'
import Construction from '../assets/images/domain-icons/Construction.png'
import Health from '../assets/images/domain-icons/Health.png'
import Insurance from '../assets/images/domain-icons/Insurance.png'
import Law from '../assets/images/domain-icons/Law.png'
import Logistics from '../assets/images/domain-icons/Logistics.png'
import Manuf from '../assets/images/domain-icons/Manuf.png'
import Retail from '../assets/images/domain-icons/Retail.png'
import Tech from '../assets/images/domain-icons/Tech.png'

import img1 from "../assets/images/Stashify-01.png"
import img2 from "../assets/images/Stashify-02.png"
import img3 from "../assets/images/Stashify-03.png"
import img4 from "../assets/images/Stashify-04.png"
import img6 from "../assets/images/Stashify-06.png"
import img7 from "../assets/images/Stashify-07.png"
import img8 from "../assets/images/Stashify-08.png"
import img9 from "../assets/images/Stashify-Web-Integrations.png"
import PurpleBg from "../assets/images/PurpleBg.png"
import call from "../assets/images/call.png"
import email from "../assets/images/email.png"


function Home() {
    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        slidesToShow: 5,
        slidesToScroll: 1, // Slides to scroll at once
        responsive: [      // Responsiveness settings
            {
                breakpoint: 1024,  // For screen size <= 1024px
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,   // For screen size <= 600px
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,   // For screen size <= 480px
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const textVideoRef = useRef(null);

    useEffect(() => {
        const textWrapper = textVideoRef.current;
        textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

        anime.timeline({ loop: true })
            .add({
                targets: '.ml3 .letter',
                opacity: [0, 1],
                easing: "easeInOutQuad",
                duration: 2250,
                delay: (el, i) => 150 * (i + 1),
            }).add({
                targets: '.ml3',
                opacity: 0,
                duration: 1000,
                easing: "easeOutExpo",
                delay: 1000,
            });
    }, []);

    const textRef = useRef(null);
    const [words, setWords] = useState([]);

    useEffect(() => {
        const textElement = textRef.current;
        const text = `Choose Stashify to save time, enhance efficiency, and simplify data retrieval. Transform the way you access information with our pioneering technology, making your workflow smoother and more productive.`;

        // Split the text into words and store them in state
        setWords(text.split(' '));

        const handleScroll = () => {
            const spans = textElement.querySelectorAll('span');
            const textPosition = textElement.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 1.3;

            if (textPosition < screenPosition) {
                spans.forEach((span, index) => {
                    setTimeout(() => {
                        span.classList.add('visible');
                    }, index * 200); // Adjust timing for each word
                });
            } else {
                spans.forEach((span) => {
                    span.classList.remove('visible');
                });
            }


        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const videoRef = useRef(null);


    const handleVideoScroll = () => {
        const video = videoRef.current;
        const rect = video.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

        if (!isVisible && !video.muted) {
            video.muted = true
        } else if (isVisible && video.muted) {
            video.muted = false
        }
    };

    useEffect(() => {
        // Attach scroll event listener
        window.addEventListener('scroll', handleVideoScroll);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('scroll', handleVideoScroll);
        };
    }, []);



    return (
        <div className="font-manrope">

            <div className="flex flex-col w-full font-manrope antialiased px-6 md:px-32
    min-h-screen items-center">
                <section className="flex flex-col md:flex-row w-full max-w-7xl  items-center justify-center
        pt-32 
        md:pt-36">
                    <div className="flex flex-col w-full justify-between space-y-6">
                        <div className="flex flex-col w-full md:space-y-2">
                            <p className="text-4xl lg:text-5xl m-0 tracking-tight">Discover & Explore</p>
                            <p className="text-4xl lg:text-[3.8rem] m-0 tracking-tight font-extrabold">Documents with Stashify AI
                            </p>
                        </div>
                        <div className="flex w-full mt-4 md:mt-0 items-end">
                            <p className="md:text-lg text-left">
                                Empower your team with a competitive edge. <b>Stashify AI</b>, powered by a <b>Large Language Model (LLM)</b>, helps you navigate documents securely and swiftly using <b>natural language</b>.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="flex flex-col lg:flex-row py-10 md:pt-4 w-full max-w-7xl  items-center justify-center
        h-full">
                    <div className="flex flex-col lg:flex-row w-full rounded-2xl
            min-h-full relative">
                        <video id="video" preload="metadata" className="w-full h-80
                md:h-[60vh] lg:h-full object-cover md:object-contain
                rounded-2xl" loop
                            autoPlay muted playsInline>
                            <source src={video}
                                type="video/mp4" />
                        </video>
                        <p id="container" className="absolute bottom-4 right-4 text-2xl md:text-5xl
                text-white font-manrope
                w-8/12 text-right">
                            <span className="tracking-tight ml3 " ref={textVideoRef}><strong className="font-extrabold">Drive</strong> into your <strong className="font-extrabold">Document Lake</strong></span>
                        </p>
                    </div>
                </section>

                <section className="flex py-10 md:py-32 w-full max-w-7xl  items-center justify-center ">
                    <div className="relative w-full max-w-9xl mx-auto overflow-hidden">
                        <Slider {...settings}>
                            <div className="item flex flex-col w-full text-center border-r-2
                    border-solid border-gray-200
                    !justify-center space-y-2 !items-center item-slide">
                                <img src={Account}
                                    alt="Slide 1" className="h-[2.5rem]
                        object-scale-down
                        m-0" />
                                <p className="m-0 text-nowrap w-full text-lg tracking-tight">Accountancy
                                    Services</p>
                            </div>
                            <div className="item flex flex-col text-center border-r-2
                    border-solid border-gray-200
                    !justify-center space-y-2 !items-center item-slide">
                                <img src={Bank}
                                    alt="Slide 3" className="h-[2.5rem]
                        object-scale-down
                        m-0" />
                                <p className="m-0 text-nowrap w-full
                        text-lg
                        tracking-tight">Banking</p>
                            </div>
                            <div className="item flex flex-col text-center border-r-2
                    border-solid border-gray-200
                    !justify-center space-y-2 !items-center item-slide">
                                <img
                                    src={Construction}
                                    alt="Slide 4" className="h-[2.5rem]
                        object-scale-down
                        m-0" />
                                <p className="m-0 text-nowrap w-full
                        text-lg
                        tracking-tight">Construction</p>
                            </div>
                            <div className="item flex flex-col text-center border-r-2
                    border-solid border-gray-200
                    !justify-center space-y-2 !items-center item-slide">
                                <img src={Health}
                                    alt="Slide 5" className="h-[2.5rem]
                        object-scale-down
                        m-0" />
                                <p className="m-0 text-nowrap w-full
                        text-lg
                        tracking-tight">Healthcare</p>
                            </div>
                            <div className="item flex flex-col text-center border-r-2
                    border-solid border-gray-200
                    !justify-center space-y-2 !items-center item-slide">
                                <img
                                    src={Insurance}
                                    alt="Slide 6" className="h-[2.5rem]
                        object-scale-down
                        m-0" />
                                <p className="m-0 text-nowrap w-full
                        text-lg
                        tracking-tight">Insurance</p>
                            </div>
                            <div className="item flex flex-col text-center border-r-2
                    border-solid border-gray-200
                    !justify-center space-y-2 !items-center item-slide">
                                <img src={Law}
                                    alt="Slide 7" className="h-[2.5rem]
                        object-scale-down
                        m-0" />
                                <p className="m-0 text-nowrap w-full
                        text-lg
                        tracking-tight">Law</p>
                            </div>
                            <div className="item flex flex-col text-center border-r-2
                    border-solid border-gray-200
                    !justify-center space-y-2 !items-center item-slide">
                                <img
                                    src={Logistics}
                                    alt="Slide 8" className="h-[2.5rem]
                        object-scale-down
                        m-0" />
                                <p className="m-0 text-nowrap w-full
                        text-lg
                        tracking-tight">Logistics</p>
                            </div>
                            <div className="item flex flex-col text-center border-r-2
                    border-solid border-gray-200
                    !justify-center space-y-2 !items-center item-slide">
                                <img src={Manuf}
                                    alt="Slide 9" className="h-[2.5rem]
                        object-scale-down
                        m-0" />
                                <p className="m-0 text-nowrap w-full
                        text-lg
                        tracking-tight">Manufacturing</p>
                            </div>
                            <div className="item flex flex-col text-center border-r-2
                    border-solid border-gray-200
                    !justify-center space-y-2 !items-center item-slide">
                                <img src={Retail}
                                    alt="Slide 10" className="h-[2.5rem]
                        object-scale-down
                        m-0" />
                                <p className="m-0 text-nowrap w-full
                        text-lg
                        tracking-tight">Retail
                                    & CPG</p>
                            </div>
                            <div className="item flex flex-col text-center border-r-2
                    border-solid border-gray-200
                    !justify-center space-y-2 !items-center item-slide">
                                <img src={Tech}
                                    alt="Slide 2" className="h-[2.5rem]
                        object-scale-down
                        m-0" />
                                <p className="m-0 text-nowrap w-full
                        text-lg
                        tracking-tight">Technology</p>
                            </div>
                        </Slider>
                    </div>
                </section>

                <section className="flex flex-col lg:flex-row py-10 md:py-4 w-fit max-w-7xl  items-center justify-center
         h-fit md:h-full relative">
                    <video id="video" ref={videoRef} preload="metadata" className="w-full h-fit md:h-[35rem] object-contain  md:object-cover shadow-xl 
                rounded-3xl" loop
                        autoPlay muted playsInline controls>
                        <source src={video2}
                            type="video/mp4" />
                    </video>
                </section>

                <section className="font-manrope flex flex-row py-12 md:pt-40 max-w-7xl  items-center justify-center">
                    <div className="flex flex-col w-full items-center">
                        <div className="w-full pl-8">
                            <p className="text-4xl md:text-5xl font-extrabold  tracking-tight leading-relax">We’re Simplifying Document Discovery for Everyone
                            </p>
                        </div>
                        <div className="flex flex-col md:flex-row mt-12 md:mt-20 space-y-12
                md:space-y-0 md:space-x-8">
                            <div className="flex md:hidden w-full md:w-6/12">
                                <img src={img1} alt="Feature"
                                    className="w-full h-auto" />
                            </div>
                            <div className="flex flex-col w-full md:w-6/12 space-y-16 mt-4
                    md:mt-18">
                                <div className="w-full">
                                    <p className="text-3xl  font-extrabold tracking-tight mt-0 text-left">Deep Search with <br />Natural Language</p>
                                    <p className="mt-6 text-black
                            w-full
                            lg:w-10/12
                            text-lg text-left">Effortlessly find the information you need with our advanced Natural Language Processing models, saving you time.</p>
                                </div>
                                <div className="w-full">
                                    <p className="text-3xl font-extrabold
                            tracking-tight text-left">Context-Aware <br />Insights with LLMs</p>
                                    <p className="mt-6 text-black
                            w-full text-lg
                            text-left
                            lg:w-10/12 lg:leading-relaxed">Receive precise, context-driven answers from your documents with the power of our intelligent Large Language Models.</p>
                                </div>
                            </div>
                            <div className="hidden md:flex w-full md:w-6/12">
                                <img src={img1} alt="Feature"
                                    className="w-full h-auto" />
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row mt-12 md:mt-20 space-y-8
                md:space-y-5 md:space-x-8">
                            <div className="w-full md:w-7/12">
                                <img className="w-full md:w-[48rem] h-auto pl-0 md:pl-20"
                                    src={img2} alt="Feature" />
                            </div>
                            <div className="flex flex-col w-full md:w-6/12
                    space-y-16">
                                <div className="w-full">
                                    <p className="text-3xl font-extrabold mt-0
                            tracking-tight">Seamless
                                        Cloud Integration</p>
                                    <p className="mt-6 text-black
                            w-full text-lg
                            text-left
                            lg:w-10/12">Easily connect to your cloud drives, accessing all your documents from one platform—no need to track where your last contract was saved.</p>
                                </div>
                                <div className="w-full">
                                    <p className="text-3xl font-extrabold mt-0
                            tracking-tight">Secure
                                        Data Handling</p>
                                    <p className="mt-6 text-black
                            w-full text-lg
                            text-left
                            lg:w-10/12">Your data is safeguarded with advanced security measures, ensuring confidentiality within your premises.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <section className="flex flex-col w-full h-full bg-gray-100 py-12 px-10 md:px-32  items-center justify-center font-manrope">
                <div className="grid grid-rows-1 lg:grid-cols-2 gap-4 text-center  max-w-7xl">
                    <div className="flex flex-col w-full items-center p-4">
                        <img className="w-80 sm:w-60 md:w-72 lg:w-80 xl:w-[30rem]"
                            src={img3}
                            alt="Automated Indexing" />
                        <p className="text-3xl 
                font-extrabold tracking-tight mt-6 mb-6">Automated
                            Indexing</p>
                        <p className=" text-lg
                 text-black ">
                            Stashify automatically tags and indexes incoming
                            documents, streamlining your document organization
                            process.
                        </p>
                    </div>
                    <div className="flex flex-col w-full items-center p-4">
                        <img className="w-80 sm:w-60 md:w-72 lg:w-80 xl:w-[30rem]"
                            src={img4}
                            alt="Enhanced OCR Capabilities" />
                        <p className="text-3xl 
                font-extrabold tracking-tight mt-6 mb-6">Enhanced
                            OCR
                            Capabilities</p>
                        <p className=" text-lg  text-black
                ">
                            Convert text from images into searchable,
                            machine-readable text with advanced OCR technology,
                            improving
                            document accessibility.
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-12 md:py-20 md:pb-28 w-full px-4 md:px-32 flex  items-center justify-center bg-cover font-manrope"
                style={{ backgroundImage: `url(${PurpleBg})` }}>
                <div className="flex flex-col w-full max-w-7xl">
                    <div className="text-center w-full">
                        <p className="text-4xl md:text-5xl font-bold tracking-tight">Here's
                            how it enhances your experience</p>
                    </div>

                    <div className="flex flex-col mt-12 md:mt-20 text-center space-y-16 md:space-y-4 px-4">
                        <div
                            className="flex flex-col lg:flex-row w-full h-full
                items-center justify-start space-y-8 md:space-y-0
                lg:space-x-24">
                            <div className='w-fit h-fit shadow-2xl rounded-3xl object-contain'>
                                <img src={img6} className="w-full
                     h-full m-0 lg:ml-0"
                                    alt="Experience 2" />
                            </div>

                            <div className="flex flex-col items-start justify-start
                    w-full
                    lg:w-7/12 space-y-4 md:space-y-8">
                                <p className="md:mt-6 text-3xl font-extrabold
                        text-left w-full
                        tracking-tight">Instant Access</p>
                                <p className="mt-2 text-black text-start
                        text-lg
                        w-full lg:w-10/12 lg:leading-relaxed">Quickly
                                    retrieve precise information from your documents
                                    by
                                    asking questions in natural language, thanks to
                                    our advanced AI.</p>
                            </div>
                        </div>

                        <div
                            className="flex flex-col lg:flex-row w-full h-full font-manrope
                items-center justify-end space-y-2 md:space-y-0
                lg:space-x-24">
                            <div className='w-fit h-fit shadow-2xl rounded-3xl object-cover'>
                                <img src={img7} className="w-full
                    flex md:hidden
                     h-full m-0 lg:ml-0"
                                    alt="Experience 2" />
                            </div>
                            <div className="flex flex-col items-start justify-end w-full
                    lg:w-7/12 space-y-4 md:space-y-8 lg:text-right
                    lg:items-end">
                                <p className="mt-6 text-3xl font-extrabold
                        tracking-tight">Effortless Interaction</p>
                                <p
                                    className="mt-2 text-black
                        text-start lg:text-right w-full
                       
                        text-lg
                        lg:leading-relaxed">
                                    Use the chat interface to interact with your
                                    data,
                                    making it easier to find what you need without
                                    manual searching.</p>
                            </div>
                            <div className='w-fit h-fit shadow-2xl rounded-3xl object-contain'>
                                <img src={img7} className="w-full
                    hidden md:flex
                     h-full m-0 lg:ml-0"
                                    alt="Experience 2" />
                            </div>
                        </div>

                        <div
                            className="flex flex-col lg:flex-row w-full h-full
                items-center space-y-8 md:space-y-0 lg:space-x-24">
                            <div className='w-fit h-fit shadow-2xl rounded-3xl object-contain'>
                                <img src={img8} className="w-full
                     h-full lg:ml-0 lg:mr-0"
                                    alt="Experience 3" />
                            </div>
                            <div className="flex flex-col items-start justify-start
                    w-full
                    md:w-7/12 space-y-4 md:space-y-8">
                                <p className="md:mt-6 text-3xl font-extrabold
                        tracking-tight">Smart Results</p>
                                <p className="mt-2 text-black text-start
                        text-lg
                        w-full md:w-10/12 lg:leading-relaxed">Benefit
                                    from AI that understands your queries and
                                    provides
                                    accurate, context-aware answers in real-time.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-6 md:py-16 md:px-24 w-full flex  items-center justify-center">
                <div className="flex flex-col lg:flex-row w-full h-full px-6 max-w-7xl
        md:px-16
        justify-start space-y-8 md:space-y-0 items-center ">
                    <div className="w-full lg:w-7/12 space-y-4 md:space-y-8">
                        <p className="text-5xl font-extrabold tracking-tight">Easy
                            Integration with Stashify</p>
                        <p className=" text-black text-lg text-left
                lg:leading-relaxed lg:w-full">Connecting your
                            cloud
                            drives
                            to Stashify is simple and smooth! Start with
                            seamless
                            integrations for Google Drive, One Drive, and
                            Dropbox
                            right away.</p>
                        <p className="text-black lg:leading-relaxed
                text-lg text-left
                lg:w-full">Looking for more? You'll
                            soon
                            have access to many other cloud services.</p>
                    </div>
                    <div className="w-full lg:w-7/12 flex items-center
            justify-center">
                        <img src={img9}
                            className="w-full md:w-[40rem] h-auto md:h-auto m-0"
                            alt="Experience 2" />
                    </div>
                </div>
            </section>

            <section id="animatedText" className="flex items-center justify-center px-6 h-full md:px-16 w-full py-24 bg-slate-50">
                <p id="textToAnimate" ref={textRef} className="text-center font-semibold 
        text-[#1A1A1A] text-3xl max-w-md md:max-w-7xl leading-relaxed space-x-4 space-y-2 lg:space-y-6">
                    {words.map((word, index) => (
                        <span
                            key={index}
                            className={`angle${(index % 4) + 1}`} // Assign angle classes dynamically
                        >
                            {word}{' '}
                        </span>
                    ))}
                </p>
            </section>


            <section id="pricing" className="py-24 relative">
                <div className="absolute h-[36.5rem] w-full top-0 bg-gradient-to-br
        from-blue-100 via-purple-200
        to-pink-100 -z-10"></div>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="mb-12 ">
                        <p className="font-manrope text-5xl text-center font-bold
                 mb-4 tracking-tight">Elevate Your Experience With Stashify</p>
                        <p className="text-gray-600 text-xl text-center leading-6 ">30
                            Days free trial. Select the plan that best fits your
                            needs.</p>

                    </div>

                    <div className="grid lg:grid-cols-3 gap-8 lg:items-center h-full">

                        <div className="group flex flex-col mx-auto w-full  max-w-sm bg-white rounded-2xl shadow-2xl transition-all duration-300 p-6 h-full min-h-[36rem] flex-shrink-0">
                            <div className="border-b border-solid border-gray-200 h-1/2 min-h-1/2">
                                <div className="w-16 h-16 rounded-full bg-indigo-50 mx-auto flex justify-center items-center transition-all duration-300  group-hover:bg-indigo-600">
                                    <FontAwesomeIcon icon={faThumbsUp} size="xl" color='#B197FC' />
                                </div>
                                <p className="font-manrope text-2xl font-bold my-3  text-center text-indigo-600">Free Trial</p>
                                <p className="text-sm md: text-gray-800  text-center py-2 ">
                                    Explore Stashify's full capabilities with a
                                    30-day
                                    free
                                    trial, including all features.
                                </p>
                            </div>
                            <div className='flex flex-col h-1/2 justify-between flex-shrink-0'>
                                <ul className="space-y-2 text-left text-md p-4 text-gray-600 group-hover:text-gray-900 h-full">
                                    <li className="flex items-center space-x-3.5">

                                        <span className="w-1.5 h-1.5 rounded-full
                            bg-indigo-600"></span>
                                        <span>Single User</span>
                                    </li>
                                    <li className="flex items-center space-x-3.5">

                                        <span className="w-1.5 h-1.5 rounded-full
                            bg-indigo-600"></span>
                                        <span>1 GB Storage</span>
                                    </li>
                                    <li className="flex items-center space-x-3.5">

                                        <span className="w-1.5 h-1.5 rounded-full
                            bg-indigo-600"></span>
                                        <span>Full Access to
                                            AI-Powered Data
                                            Retrieval </span>
                                    </li>
                                    <li className="flex items-center space-x-3.5">

                                        <span className="w-1.5 h-1.5 rounded-full
                            bg-indigo-600"></span>
                                        <span>Integration with Cloud
                                            Drives</span>
                                    </li>
                                </ul>
                                <a href="https://app.stashify.ai/sign-up" target="_blank" rel="noreferrer" className="py-2.5 px-5
                    bg-indigo-50 shadow-sm rounded-full
                    transition-all duration-500
                    text-indigo-600 font-semibold text-center w-fit
                    mx-auto group-hover:bg-indigo-600
                    group-hover:text-white ">Sign up</a>
                            </div>
                        </div>

                        <div className="group flex flex-col mx-auto w-full  max-w-sm bg-white rounded-2xl shadow-2xl transition-all duration-300 p-6 h-full min-h-[36rem] flex-shrink-0 ring-2 ring-blue-800">
                            <div className="border-b border-solid border-gray-200 h-1/2 min-h-1/2">
                                <div className="w-16 h-16 rounded-full bg-indigo-50 mx-auto flex justify-center items-center transition-all duration-300 group-hover:bg-indigo-600">
                                    <FontAwesomeIcon icon={faBriefcase} size="xl" color='#B197FC' />
                                </div>
                                <p className="font-manrope text-2xl font-bold  my-3 text-center text-indigo-600">Standard
                                    Plan</p>
                                <div className="flex items-center justify-center">
                                    <span className="font-manrope text-4xl  font-medium text-gray-900">$99</span>
                                    <span className="text-xl text-gray-500 ml-3">|&nbsp; Month</span>
                                </div>
                                <p className="text-sm md: text-gray-800  py-4 text-center">
                                    Perfect for professionals and small
                                    teams
                                    seeking to
                                    optimize document retrieval and data
                                    management.
                                </p>
                            </div>
                            <div className='flex flex-col h-1/2 justify-between flex-shrink-0'>
                                <ul className=" space-y-2 text-left text-md p-4
                    text-gray-600 group-hover:text-gray-900">
                                    <li className="flex items-center space-x-3.5">

                                        <span className="w-1.5 h-1.5 rounded-full
                            bg-indigo-600"></span>
                                        <span>Single User</span>
                                    </li>
                                    <li className="flex items-center space-x-3.5">

                                        <span className="w-1.5 h-1.5 rounded-full
                            bg-indigo-600"></span>
                                        <span>1 GB Storage</span>
                                    </li>
                                    <li className="flex items-center space-x-3.5">

                                        <span className="w-1.5 h-1.5 rounded-full
                            bg-indigo-600"></span>
                                        <span>Priority Support</span>
                                    </li>
                                    <li className="flex items-center space-x-3.5">

                                        <span className="w-1.5 h-1.5 rounded-full
                            bg-indigo-600"></span>
                                        <span>Advanced Analytics </span>
                                    </li>
                                    <li className="flex items-center space-x-3.5">

                                        <span className="w-1.5 h-1.5 rounded-full
                            bg-indigo-600"></span>
                                        <span>Optional Additional
                                            Storage</span>
                                    </li>
                                    <li className="flex items-center space-x-3.5">

                                        <span className="w-1.5 h-1.5 rounded-full
                            bg-indigo-600"></span>
                                        <span>Optional Additional
                                            Users</span>
                                    </li>
                                </ul>
                                <a href="https://app.stashify.ai/sign-up" target="_blank" rel="noreferrer"
                                    className="py-2.5 px-5  bg-indigo-50 shadow-sm rounded-full  transition-all duration-500  text-indigo-600 font-semibold text-center w-fit mx-auto group-hover:bg-indigo-600 group-hover:text-white ">
                                    Buy now
                                </a>
                            </div>
                        </div>

                        <div className="group flex flex-col mx-auto w-full  max-w-sm bg-white rounded-2xl shadow-2xl transition-all duration-300 p-6 h-full min-h-[36rem] flex-shrink-0">
                            <div className="border-b border-solid border-gray-200 h-1/2 min-h-1/2">
                                <div className="w-16 h-16 rounded-full
                        bg-indigo-50 mx-auto flex justify-center
                        items-center transition-all duration-300
                        group-hover:bg-indigo-600">
                                    <FontAwesomeIcon icon={faBuilding} size="xl" color='#B197FC' />
                                </div>
                                <p className="font-manrope text-2xl
                        font-bold my-3 text-center
                        text-indigo-600">Enterprise Plan</p>
                                <p className="text-sm md:
                        text-gray-800
                        py-4 text-center">
                                    Tailored solutions designed for
                                    organizations with
                                    specific requirements. Contact us to
                                    create
                                    a plan
                                    that
                                    aligns with your business needs.
                                </p>
                            </div>
                            <div className='flex flex-col h-1/2 justify-between flex-shrink-0'>
                                <ul className="space-y-2 text-left text-md
                    text-gray-600 p-4
                    group-hover:text-gray-900">
                                    <li className="flex items-center
                        space-x-3.5">

                                        <span className="w-1.5 h-1.5
                            rounded-full bg-indigo-600"></span>
                                        <span>All Standard Plan
                                            Features</span>
                                    </li>
                                    <li className="flex items-center
                        space-x-3.5">

                                        <span className="w-1.5 h-1.5
                            rounded-full bg-indigo-600"></span>
                                        <span>Customizable Storage and
                                            User
                                            Limits</span>
                                    </li>
                                    <li className="flex items-center
                        space-x-3.5">

                                        <span className="w-1.5 h-1.5
                            rounded-full bg-indigo-600"></span>
                                        <span>Option to Deploy on Your
                                            Own Data
                                            Center
                                        </span>
                                    </li>
                                    <li className="flex items-center
                        space-x-3.5">

                                        <span className="w-1.5 h-1.5
                            rounded-full bg-indigo-600"></span>
                                        <span>Dedicated Account
                                            Manager</span>
                                    </li>
                                    <li className="flex items-center
                        space-x-3.5">

                                        <span className="w-1.5 h-1.5
                            rounded-full bg-indigo-600"></span>
                                        <span>Enhanced Security
                                            Features</span>
                                    </li>
                                </ul>
                                <a href="#contact" className="py-2.5 px-5
                    bg-indigo-50 shadow-sm rounded-full
                    transition-all duration-500
                    text-indigo-600 font-semibold
                    text-center w-fit mx-auto
                    group-hover:bg-indigo-600
                    group-hover:text-white">Contact
                                    us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact" className="flex flex-col items-center justify-center  py-20 px-5 bg-gradient-to-br  from-blue-100 via-purple-200  to-pink-100  w-full">
                <div className="flex flex-col items-center justify-center gap-10  max-w-7xl">
                    <div className="flex flex-col w-full text-center p-4">
                        <p className="text-4xl font-bold mb-4 tracking-tight lg:text-5xl">Reach out to
                            us</p>
                        <p className="text-lg">Whether it is
                            about
                            inquiries, custom
                            solutions,<br className="hidden" /> or support, we’re here
                            to
                            help</p>
                    </div>
                    <div className="flex flex-col md:flex-row gap-5 w-full">
                        <div className="card rounded-3xl py-8 px-10 shadow-md items-center justify-center flex flex-row w-full bg-[#EDEAF7] space-x-8" >
                            <div className="flex flex-row  mb-4 bg-white shadow-md rounded-full self-center items-center justify-center flex-shrink-0 w-16 h-16">
                                <img src={call} alt="Phone Call Icon" className="size-10" />
                            </div>
                            <div className='flex flex-col w-full'>
                                <div className='text-left w-full'>
                                    <p className="text-lg font-bold mb-2">Give us a call</p>
                                    <p className="mb-4">We are standing by 24/7 hours</p>
                                </div>
                                <a href="tel:+442035403828" className="inline-block  bg-black  text-white py-2 px-5 rounded-full font-bold  hover:bg-gray-800 no-underline mt-auto w-fit">
                                    (+44)  20 3540 3828
                                </a>
                            </div>
                        </div>
                        <div className="card rounded-3xl py-8 px-10 shadow-md items-center justify-center flex flex-row w-full bg-[#EDEAF7] space-x-8"
                        >
                            <div className="flex flex-row  mb-4 bg-white shadow-md rounded-full self-center items-center justify-center flex-shrink-0 w-16 h-16">
                                <img src={email} alt="Email Icon"
                                    className="size-10" />
                            </div>
                            <div className='flex flex-col w-full'>
                                <div className='text-left w-full'>
                                    <p className="text-lg font-bold mb-2">Email us</p>
                                    <p className="w-full pb-2">Drop us an email and you’ll receive a reply within 24 hour</p>
                                </div>
                                <a href="mailto:info@stashify.ai"
                                    className="inline-block  bg-black  text-white py-2 px-16 rounded-full font-bold text-center hover:bg-gray-800 no-underline mt-auto w-fit">Email
                                    us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div >
    )
}

export default Home;
